import React, { useState } from 'react'

import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckRounded'

import ContactForm from '../components/ContactForm'

const SupportContactForm = () => {
	const [status, setStatus] = useState('capture')
	const [error, setError] = useState(null)

	const handleBeforeSubmit = () => setStatus('sending')

	const handleAfterSubmit = err => {
		if (err) {
			setStatus('error')
			setError(err.message)
		} else {
			setStatus('success')
		}
	}
	return status === 'success' ? (
		<center>
			<CheckIcon sx={{ fontSize: 100 }} />
			<Typography variant={'h6'}>Thanks! We'll be in touch!</Typography>
		</center>
	) : (
		<ContactForm
			afterSubmit={handleAfterSubmit}
			beforeSubmit={handleBeforeSubmit}
			error={error}
			status={status}
		/>
	)
}

export default SupportContactForm
