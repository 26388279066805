export const iconStyles = {
	color: 'text.secondary',
	fontSize: 20,
}

export const lineProps = {
	alignItems: 'flex-start',
	direction: 'row',
	spacing: 2,
	sx: {
		mb: 1,
	},
}
