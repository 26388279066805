/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Grid, Icon, Link, Paper, Stack, Typography} from '@mui/material';
import SupportContactForm from '../support/SupportContactForm';
import {iconStyles, lineProps} from '../support/styles';
import {DefaultLayout as MDXLayout} from "../components/DefaultLayout";
export {Head} from '../components/DefaultLayout';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h5: "h5"
  }, _provideComponents(), props.components), {Block, Hero, HeroContent, TextGradient, TextCaption, Spacer} = _components;
  if (!Block) _missingMdxReference("Block", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!HeroContent) _missingMdxReference("HeroContent", true);
  if (!Spacer) _missingMdxReference("Spacer", true);
  if (!TextCaption) _missingMdxReference("TextCaption", true);
  if (!TextGradient) _missingMdxReference("TextGradient", true);
  return React.createElement(React.Fragment, null, React.createElement(Block, {
    color: 'neutral'
  }, React.createElement(Hero, null, React.createElement("center", null, React.createElement(HeroContent, null, React.createElement(_components.h1, null, React.createElement(TextGradient, null, "Contact M2North Support")))))), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(Grid, {
    container: true,
    spacing: {
      sm: 4,
      md: 12
    }
  }, React.createElement(Grid, {
    item: true,
    xs: 12,
    md: 7,
    lg: 6
  }, React.createElement(Paper, {
    sx: {
      p: 3
    }
  }, React.createElement(SupportContactForm))), React.createElement(Grid, {
    item: true,
    xs: 12,
    md: 5,
    lg: 6
  }, React.createElement(_components.h5, null, "USA"), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "place"), "\n", React.createElement(TextCaption, null, " 8 The Green, Suite # 10941, Dover, DE 19901")), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "phone"), React.createElement(TextCaption, null, React.createElement(Stack, null, React.createElement("span", null, "+1 416 505-5812"), "\n", React.createElement("span", null, "Toll Free: +1 866 839-5910")))), React.createElement(Spacer, {
    size: 2
  }), React.createElement(_components.h5, null, "United Kingdom"), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "place"), "\n", React.createElement(TextCaption, null, "31 Woodcote Close, Kingston Upon Thames, KT2 5LZ")), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "phone"), "\n", React.createElement(TextCaption, null, "+44 203 322 3849")), React.createElement(Spacer, {
    size: 2
  }), React.createElement(_components.h5, null, "Africa"), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "mail"), "\n", React.createElement(TextCaption, null, "Unit 4, 8 Osborn Lane, Bedfordview, 2008, Gauteng, South Africa")), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "place"), "\n", React.createElement(TextCaption, null, "PostNet Suite 423, Private Bag X19, Gardenview, 2047, South Africa")), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "phone"), React.createElement(TextCaption, null, React.createElement(Stack, null, React.createElement("span", null, "SA Sharecall: 0860 000 626"), "\n", React.createElement("span", null, "International: +27 11 856-2000")))), React.createElement(Stack, lineProps, React.createElement(Icon, {
    sx: iconStyles
  }, "fax"), React.createElement(TextCaption, null, React.createElement(Stack, null, React.createElement("span", null, "SA: 086 214 2349"), "\n", React.createElement("span", null, "International: 0027 86 214 2349"))))))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
